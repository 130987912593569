
import { defineComponent } from "vue";
//import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  components: {
    //Dropdown1,
    KTUserMenu,
  },
  setup() {
    return {
      toolbarWidthFluid,
    };
  },
});
